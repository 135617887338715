
import { createStore } from 'vuex';
import axios from 'axios';
import VuexPersistence from 'vuex-persist'
import Cookies from "js-cookie";
import axiosIns from './plugins/axios';

const vuexPersist = new VuexPersistence({
    key: 'my-app',
    storage: window.localStorage,
    reducer: (state) => ({
        // Specify only the state you want to persist
        clicked: state.clicked,
        darkMode: state.darkMode,
        subscriptions: state.subscriptions,
        subscription_modules: state.subscription_modules,
        subscribeNow: state.subscribeNow,
        moduleSubscribed: state.moduleSubscribed,
        toggleSideBar: state.toggleSideBar
    }),
    // Alternatively, you can use the 'paths' option
    paths: ['IsLogin', 'clicked', 'darkMode', 'subscriptions', 'subscription_modules', 'subscribeNow', 'moduleSubscribed', 'toggleSideBar'] // Persist only 'user' and 'settings' state
});

const store = createStore({
    state: {
        image: null,
        user: null,
        access_token: null,
        IsLogin: false,
        clicked: false,
        darkMode: false,
        subscriptions: [],
        subscription_modules: [],
        subscribeNow: null,
        moduleSubscribed: false,
        toggleSideBar: false
    },
    plugins: [vuexPersist.plugin],
    mutations: {
        set_user(state, payload) {
            state.user = payload;
        },
        set_token(state, payload) {
            state.access_token = payload;
        },
        set_islogin(state, payload) {
            state.IsLogin = payload;
        },
        set_image(state, payload) {
            state.image = payload;
        },
        set_clicked(state, payload) {
            state.clicked = payload;
        },
        set_darkMode(state, payload) {
            state.darkMode = payload;
            localStorage.setItem('dark', payload);
            if (payload == true) {
                document.documentElement.classList.toggle('dark-mode');
            } else {
                document.documentElement.classList.remove('dark-mode');
            }
        },
        set_subscriptions(state, payload) {
            state.subscriptions = payload;
        },
        set_subscriptionsModule(state, payload) {
            state.subscription_modules = payload;
        },
        set_subscribeNow(state, payload) {
            state.subscribeNow = payload;
            state.user.year = payload;
        },
        set_moduleSubscribed(state, payload) {
            state.moduleSubscribed = payload;
        },
        set_toggleSideBar(state, payload) {
            state.toggleSideBar = payload;
        },
        set_autorization(state,payload){
            return axiosIns.defaults.headers.Authorization = 'Bearer '+payload;
        }
    },
    getters: {
        get_user(state) {
            return state.user;
        },
        get_token(state) {
            return state.access_token;
        },
        get_islogin(state) {
            return state.IsLogin;
        },
        get_image(state) {
            return state.image;
        },
        get_clicked(state) {
            return state.clicked;
        },
        get_darkMode(state) {
            return state.darkMode;
        },
        get_subscriptions(state) {
            return state.subscriptions;
        },
        get_subscriptionsModule(state) {
            return state.subscription_modules
        },
        get_subscribeNow(state) {
            return state.subscribeNow;
        },
        get_moduleSubscribed(state) {
            return state.moduleSubscribed;
        },
        is_residatant_subscriber(state) {
            const years = ['1', '2', '3', '4', '5', '6'];
            return years.every(value => Object.values(state.subscriptions).includes(value));
        },
        get_toggle_sidebar(state) {
            return state.toggleSideBar;
        }
    },
    actions: {
        registerAction({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_API_BASE_URL + "/auth/register", payload).then(({ data }) => {
                    commit('set_token', data.access_token)
                    commit('set_user', data.user)
                    commit('set_islogin', true)
                    resolve(data)
                }).catch(errors => {
                    reject(errors)
                })
            })
        },

        loginAction({ commit }, payload) {
            return new Promise((resolve, reject) => {
                //console.log(process.env.VUE_APP_API_BASE_URL)
                axios.post(process.env.VUE_APP_API_BASE_URL + "/auth/login", payload).then(({ data }) => {
                    Cookies.set("access_token", data.access_token, { expires: 365, path: '/', secure: true });
                    commit('set_token', data.access_token)
                    commit('set_user', data.user)
                    commit('set_image', data.user.profil_image)
                    commit('set_islogin', true)
                    axiosIns.defaults.headers.Authorization = 'Bearer '+data.access_token;
                    resolve(data)
                }).catch(errors => {
                    reject(errors)
                })
            })
        },

        loginSocialAction({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_API_BASE_URL + "/auth/social", payload).then(({ data }) => {
                    Cookies.set("access_token", data.access_token, { expires: 365, path: '/', secure: true });
                    commit('set_token', data.access_token)
                    commit('set_user', data.user)
                    commit('set_islogin', true)
                    axiosIns.defaults.headers.Authorization = 'Bearer '+data.access_token;
                    resolve(data)
                }).catch(errors => {
                    reject(errors)
                })
            })
        },

        logoutAction(context) {
            return new Promise((resolve, reject) => {
                /*axiosIns.post(process.env.VUE_APP_API_BASE_URL + "/auth/logout", Cookies.get('access_token'), {
                    headers: {
                        "Authorization": 'Bearer ' + Cookies.get('access_token')
                    }
                })*/
                axiosIns.post('/auth/logout')
                .then(res => {
                    Cookies.remove('access_token');
                    context.commit('set_token', null)
                    context.commit('set_islogin', false)
                    context.commit('set_image', null)
                    context.commit('set_darkMode', false)
                    context.commit('set_subscriptions', [])
                    context.commit('set_subscribeNow', null)
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },

        getCurrentUser(context, payload) {
            return new Promise((resolve, reject) => {
                axiosIns.get("/auth/user-profile", {
                    params: {
                        webGLFingerprint: payload
                    }
                }).then((response) => {
                    context.commit('set_token', Cookies.get('access_token'))
                    context.commit('set_user', response.data)
                    //context.commit('set_image', response.data.user.profil_image)
                    context.commit('set_islogin', true)
                    resolve(response);
                });
            });
        },
    }
});

export default store;